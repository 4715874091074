/*
<14:04:58> "Fr33g !t |Mirko": 15 padding col-md
<14:05:29> "Fr33g !t |Mirko": inBox padding 20 30 30 30
<14:07:20> "Fr33g !t |Mirko": forum font
<14:11:04> "Moe|xRav3nz" stupst Sie an: mirko du pisser
<14:15:47> "Fr33g !t |Mirko": header 500px hught
<14:16:24> "Viruz / Reuß": Header col 70 70 70
<14:16:58> "Viruz / Reuß": Dunkel Font col 70 70 70
<14:17:28> "Viruz / Reuß": Hell font col 245 245 245
<14:18:14> "Viruz / Reuß": Background 245 245 245
<14:18:53> "Viruz / Reuß": kacheln 100% weis 2 px round corner, schatten guck ich
<14:19:31> "Viruz / Reuß": Homepage Headline font size 54px
<14:20:35> "Fr33g !t |Mirko": row 150px
<14:21:26> "Fr33g !t |Mirko": headline to content 60
<14:22:04> "Viruz / Reuß": 29 124 184 blau
*/
$light_color: rgb(245,245,245);
$dark_color: rgb(70,70,70);
$clan_color: #0074C8;
$headline_font_size: 3.85em; //54px

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
    -o-transform: $transforms;
	transform: $transforms;
}

@mixin scale($x){
    @include transform(scale($x));
}

@mixin translateZ ($x) {
	@include transform(translateZ($x));
}

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.cod-0{
  color: #fff;
}
.cod-1{
  color: #FF4545 ;
}
.cod-2{
  color: #0F3;
}
.cod-3{
  color: yellow;
}
.cod-4{
  color: blue;
}
.cod-5{
  color: #0CF;
}
.cod-6{
  color: #FF00FF;
}
.cod-7{
  color: #999999;
}
.cod-8{
  color: rose;
}
.cod-9{
  color: gray;
}

.bg-clan{
    color: $light_color;
    background-color: $clan_color;
    a{
        color: $light_color;
        background-color: $clan_color;
    }
}


@mixin media-sm {
  @media (min-width: 576px){
    @content;
  }
}

@mixin media-md {
  @media (min-width: 992px){
    @content;
  }
}

@mixin media-lg {
  @media (min-width: 1200px){
    @content;
  }
}

body{
  font-family: 'Oxygen', sans-serif;
  background: $light_color;
  color: $dark_color;
  overflow-y: scroll;
}

a{
  text-decoration: none;
  color: $dark_color;
  &:hover{
      text-shadow: 0 0 .05px $dark_color, 0 0 .05px $dark_color;
  }
  &:focus, &:hover{
      color: $dark_color;
      text-decoration: none;
  }
}

.navbar-custom{
	background: $dark_color;

    font-family: 'Titillium Web', sans-serif;

    -moz-box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.3);

    padding: 0;

	.navbar-nav{
		.active > .nav-link, .nav-link{
			color: $light_color;
            font-weight: 600;
		}
	}

	.dropdown-menu{
			margin: 0;
            @include transform(translateX(-25%));
	}
	.dropdown-menu, .dropdown-item{
			color: $light_color;
			background: $dark_color;
	}
	.dropdown-item:hover{
			color: $clan_color;
			background: $dark_color;
			text-decoration: none;
	}

	.nav-hover{
			.nav-text, .nav-image {
					@include media-md{
							@include transition(all 0.25s ease 0s);
							@include translateZ(0);
							position: absolute;
							left: 0;
							top: 0;
							height: 50px;
							width: 100%;
							text-align: center;
							line-height: 50px;
					}
			}
			.nav-image{
					&:before{
							font-size: 30px!important;
							margin-right: 0;
							margin-left: 0;
					}
					margin-top: -50px;
					color:#0074c8!important;
			}
			overflow: hidden;
			height: 50px;
			position: relative;
	}

	@include media-md{
		height: 50px;

		&.sticky-top{
				z-index: 500;
				position: fixed!important;
				position: -webkit-sticky;
				top:0!important;
				@include translateZ(0);
				.clanlogo{
                    img{
                            @include transform(scale(0.6) translateY(3px));
                    }
                    &:hover{
                        img{
                            @include transform(scale(0.66) translateY(3px));
                        }
                    }
				}
		}
		.navbar-nav{
			width: 100%;
		}
		.nav-item{
			width: 11%;
			text-align: center;
			&:hover{
					.nav-text{
							margin-top: 50px;
					}
					.nav-image{
							margin-top: 5px;
					}
			}
		}
		.dropdown-toggle:after{
			display: none;
		}
	}

	@include media-lg{
		.nav-item{
			width: 125px;
		}
		.navbar-collapse{
			width: 1200px;
			margin: 0 auto;
		}
	};

	.clanlogo{
				overflow: visible;
				position: relative;
				width: 200px;

				&:hover{
                    img{
                        @include scale(1.1);
                    }
				}
				img{
						width: 200px;
						position: absolute;
						top: -23px;
						left: -5px;

                        @include scale(1.0);
						@include translateZ(0);
						@include transition(all 0.2s ease-in-out);
                        padding-top: 4px;
				}
	}

	&.navbar-relative{
			margin-top: 50px;
			margin-bottom: 50px;
	}
	&.navbar-absolute{
			top:0;
			left: 0;
			width: 100%;
			position: fixed;
			@include media-md{
				position:absolute;
				top: 50px;

			}


	}
}

@media (min-width: 1200px){
  .container {
      width: 1500px;
  }
}

.header-image{
  &.header-image-sm{
      height: 350px;
      .header-background{
          height: 370px;

      }
  }
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: -5;

  text-align: center;
  color: $dark_color;

  height: 500px;

  .header-background{
    background: url('../img/server/header_index.jpg') $light_color no-repeat;
    background-size: cover;
    background-position: center;

    position: absolute;
    left:-50px;
    top: -10px;

    height: 520px;
    width: 105%;
    z-index: -100;

    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);

    display: block;
  }
  .header-title{
    padding-top: 50px;

    @include media-md{
      padding-top: 185px;
    }

    h1{
      font-size: 2em;
      @include media-md{
        font-size: 3.5em;
      }
    }
  }
  .header-subtitle{
    padding-top: 60px;
    margin: 0 auto;
    font-size: 1.5em;

    @include media-md{
      width: 600px;
    }
  }
}


.space-bottom{
  margin-bottom: 150px;
}

.space-top{
  margin-top: 50px;
  &.space-top-sm{
      margin-top: 30px;
  }
}

.settings-box{
	height: 30px;
	background: #fff;
	width: 100%;

    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
	form{
		height: 30px;
	}
    .dropdown-menu{
            margin: 0;
            @include transform(translateX(-12%));
    }
}

.headline{
  margin-bottom: 30px;
  text-align: center;
  font-size: 2em;

  @include media-md{
    margin-bottom: 60px;
    font-size: $headline_font_size;
  }

}

.server-container-outer{
    .slick-list{
        overflow: visible;
    }
  overflow: hidden;
  width: 100%;
  padding: 5px 0;
  position:relative;
  cursor:pointer;

  @include media-md{
    height: (400px/16*9);
    width: 1500px;
  }

  .shadow{
    display: none;
    @include media-md{
      display: block;
    }
    &:before,&:after{
      width: 100px;
      height: 100%;
      display: block;
      position: absolute; top: 0;
      content: '';
      z-index: 10;
    }
    &:before{
      left: 0;
      box-shadow: inset 50px 0px 50px 0px $light_color;
    }
    &:after{
      right: 0;
      box-shadow: inset -50px 0px 50px 0px $light_color;
    }
  }
}

.server-box-background{
    width: 380px;
    height: (380px/16*9);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);

    margin: 0 10px;
    float: left;
    border: 5px solid #fff;
    background-image: url('img/maps/loadscreen_default.jpg');
    & .server-box{
        width: 100%;
        height: 100%;
    }

}

.server-box{

  background-image: url('../img/maps/loadscreen_default.jpg');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;


  .text{
    height: 25px;
    line-height: 25px;
    background: #fff;
    color: $dark_color;
    margin: 0 auto;
    width: 75%;
    text-align: center;
    position: relative;
    &:before{
      display: block;
      content: "";
      height: 0;
      width: 0;
      border: 13px solid #fff;
      border-bottom: 13px solid transparent;
      border-left: 13px solid transparent;
      position: absolute;
      left: -25px;
      top: 0;
    }
    &:after{
      display: block;
      content: "";
      height: 0;
      width: 0;
      border: 13px solid #fff;
      border-bottom: 13px solid transparent;
      border-right: 13px solid transparent;
      position: absolute;
      right: -25px;
      top: 0;
    }
  }
}

.livesearch{
  border: 0;
  outline: 0;
  border-bottom: 1px solid black;
  background-color:transparent;
  margin-bottom: 15px;
  &::-webkit-input-placeholder{
    text-align:center;
  }
  &:-moz-placeholder{
    text-align:center;
  }
  &::-moz-placeholder{
    text-align:center;
  }
  &:-ms-input-placeholder{
    text-align:center;
  }
}
.row {
  & > div{
    padding: 0 15px;
  }
}
.boxed{
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);

  border-radius: 2px;
  padding: 20px 30px 30px 30px;
  margin: 0 0 30px 0;

  & >tbody> tr td:first-child, & > tbody > tr th:first-child{
    padding-left: 10px;
  }
  & >tbody> tr td:last-child, & > tbody > tr th:last-child{
    padding-right: 10px;
  }
  &.boxed-sm{
    height: 250px;
    .table-scrollable{
        height: 160px;
    }
  }
  &.boxed-md{
      height: 375px;
      .table-scrollable{
          height: 283px;
      }
  }
  &.boxed-lg{
      height: 530px;
  }
  .graph{margin: 0;}
  h2{
    font-size: 1.3rem;
    margin-bottom: 20px;
  }
  &.boxed-dark{
      background: $dark_color;
  }
}

.d-flex{
	.boxed{height: 100%;}
	& > [class*='col-'] {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
	}
}

.table-scrollable{
	overflow-y: auto;
	max-height: 438px;
}
.table{
    tr:before{
        display: block;
        content: " ";
        margin-top: -285px;
        height: 285px;
        visibility: hidden;
    }
		&.table-xs{
			td, th{
				padding: 0rem;
			}
		}
    &.table-borderless{
        tr,td,th{
            border: 0;
        }
    }
    &.table-line{
        tbody{
            td,th{
                border-top: 1px solid $dark_color
            }
            tr:first-child{
                td,th{
                    border-top: none;
                }
            }
        }
        thead{
            td,th{
                border-bottom: 1px solid $dark_color;
            }
        }
    }
    .thead.noborder{
        td,th{
            border-top: 0;
        }
    }
		.table-date{
			min-width: 150px;
		}
		.table-client{
			min-width: 150px;
            max-width: 250px;
		}
        .table-server{
            min-width: 150px;
        }
        .table-trophy{
            width: 41.5%;
        }
}

.flag-icon-clan {
    background-image: url('../../img/logo_white.svg');
}
.clientname{
  max-width: 200px;
  a{
    max-width: 200px;
    text-overflow: ellipsis;;
    white-space:nowrap;
    overflow:hidden;
    table-layout: fixed;
    display: block;
  }
}
.time{
  width: 75px;
}
.graph{
  width: 100%;
  height: 438px;
  margin: 0 15px 30px 15px;
  position: relative;
  & > div{
    position: absolute;
    top: 0;
  }
  &.graph-sm{
      height: 158px;
  }
}

.google-visualization-tooltip{
    z-index: 1000;
 }

.footer{
    font-size: 0.75em;
 background: rgb(75,75,75);
  width: 100%;
  color: $light_color;
  a{
      color: $light_color;
  }
    .copyright, &.copyright{
      line-height: 50px;
      min-height: 50px;
      background: $dark_color;
      -webkit-box-shadow: 0px 2px 4px 0px black;
      -moz-box-shadow: 0px 2px 4px 0px black;
      box-box-shadow: 0px 2px 4px 0px black;
  	}
    &.copyright{
        margin-bottom: 0;
    }
}

.blue{
  color: $clan_color;
}

.info-box{
  height: 70px;
  font-size: 18px;
  line-height: 70px;
  padding: 0 5px;
  margin-bottom: 30px;
  .blue{
      font-size: 25px;
  }
}

.form-control-xs{
    padding: 0.10rem .5rem;
    font-size: .775rem;
    border-radius: 2px;

    }
tags-input .host{
    position: relative;
}
tags-input .tag-list{
}
tags-input .tags {
    border-radius: 2px;
    box-shadow: none;
    height: 20.19px;
    width: 100%;
    color: #464a4c;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    padding: 0.10rem .5rem;
    font-size: .775rem;
    border-radius: 2px;
    position: relative;
}
tags-input .tags{
    padding: 0;
}
tags-input .tags .input{
    padding: 0.10rem .5rem;
    font-size: .775rem;
    margin: 0;
    border: 0;
    height: auto;
    line-height: 1.25;
color: #464a4c;
background-color: transparent;
}

tags-input .tags .tag-item{
    display: inline-block;
    padding: .1em .4em;
    margin: 0;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    height: auto;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    background: #0074C8;
}

tags-input .autocomplete{width: 500px;}

tags-input .tags .tag-item .remove-button {
    color: #fff;
}


/* Google Chart Tooltip Fix */
svg > g > g:last-child { pointer-events: none }

.fa,.glyphicon{

    &.fa-chevron-left::before, &.glyphicon-chevron-left:before,
    &.fa-chevron-right::before, &.glyphicon-chevron-right:before{
        font-family: "website_font_3xp";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display:inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */ /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */ /* remove if not needed */
        margin-left: .2em; /* you can be more comfortable with increased icons size */ /* font-size: 120%; */ /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; /* Uncomment for 3D effect */ /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }

    &.fa-chevron-left::before, &.glyphicon-chevron-left:before{
        content: '\e8d6';
    }

    &.fa-chevron-right::before, &.glyphicon-chevron-right:before{
        content: '\e8d7';
    }
}


/*

.navbar-custom2{
    background: $dark_color;
    padding: .4rem 1rem;

    .dropdown-menu{
        margin: 0;
    }

    .dropdown-menu, .dropdown-item{
        color: $light_color;
        background: $dark_color;
    }
    .dropdown-item:hover{
        color: $light_color;
        background: $dark_color;
        text-decoration: underline;
    }

    @include media-md{
        &.sticky-top{
            z-index: 500;
            position: fixed!important;
						position: -webkit-sticky;
            top:0!important;
            @include translateZ(0);
            .clanlogo{
                img{
                    @include transform(scale(0.6) translateY(2px));
                }
            }
        }
        & .navbar-center{
            margin: 0 auto;
        }
        .nav-item{
          text-align: center;
          &:hover{
              .nav-text{
                  margin-top: 50px;
              }
              .nav-image{
                  margin-top: 0;
              }
          }
        }
        .dropdown-toggle:after{
            display: none;
        }
				.collapse{
					width: 1200px;
					margin: 0 auto;
				}
    }

    @include media-lg{
        .nav-item{
          width: 125px;
        }
				.clanlogo{
							overflow: visible;
							position: relative;
							width: 200px;

							&:hover{
								@include scale(1.1);
							}
							img{
									width: 220px;
									position: absolute;
									top: -30px;
									left: -20px;

									@include translateZ(0);
									@include transition(all 0.2s ease-in-out);
							}
				}
        & .navbar-center{
          width: 1200px;
          margin: 0 auto;
        }
        &.navbar-relative{
            margin-top: 50px;
            margin-bottom: 50px;
        }
        &.navbar-absolute{
            width: 100%;
            position:absolute;
            top: 50px;
            left: 0;
        }
    }

    .nav-hover{
        .nav-text, .nav-image {
            @include media-md{
                @include transition(all 0.25s ease 0s);
                @include translateZ(0);
            }

            position: absolute;
            left: 0;
            top: 0;
            height: 38px;
            width: 100%;
            text-align: center;
            line-height: 38px;
        }
        .nav-image{
            &:before{
                font-size: 38px!important;
                margin-right: 0;
                margin-left: 0;
            }
            margin-top: -50px;
            color:#0074c8!important;
        }
        overflow: hidden;
        height: 38px;
        position: relative;
    }

    .navbar-nav{
      .active > .nav-link, .nav-link{
        color: $light_color;
      }
    }
}

*/
